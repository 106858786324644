<!--
    Created by 程雨喵'mac on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：下载中心-下载列表
-->
<style lang="less">
#download-list-content {
  .col-text-strong {
    color: @adornColor;
    font-weight: 600;
  }
}
</style>

<template>
  <div id="download-list-content">
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:download:list'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import DownloadApiSet from '@/request/api/DownloadApiSet';
import { DownloadDataModel } from './model/DownloadDataModel';
import { downloadExcel } from '@/libs/download';
export default {
  components: {},
  data () {
    return {
      settingItems: [
        BaseSettingModel.initData('刷新数据', 'refresh', 'md-refresh-circle').setPermission('system:download:list')
      ],
      page: { current: 1, total: 0 },
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('name', '报表名称').setSpan({}),
        BaseTableColumn.initData('createTime', '操作时间').setSpan({}).setWidth(150),
        BaseTableColumn.initData('createBy', '操作人').setSpan({}),
        BaseTableColumn.initData('generateTime', '生成时间').setSpan({}).setWidth(150),
        BaseTableColumn.initData('statusDesc', '状态').setSpan({}),
        BaseTableColumn.initData('setting', '操作').setWidth(140).setAlign('center').setButtons([
          { title: '下载', name: 'download', permission: 'system:download:query' }
        ], ({ name }, row, index) => {
          switch (name) {
          case 'download':
            if (row.status === 0) {
              return this.$Message.warning('文件生成中，暂不可下载');
            }
            if (row.status === 2) {
              return this.$Message.warning('文件生成失败，不可下载');
            }
            this.requestExport(row);
            break;
          }
        })
      ],
      tableData: []
    };
  },
  created () {
    this.$store.commit('setDownloadAmount', 0);
    this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSetting (key) {
      switch (key) {
      case 'refresh':
        this.page.current = 1;
        this.requestData();
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      const api = DownloadApiSet.downloadList;
      api.params = {
        pageNo: this.page.current,
        pageSize: this.$config.pageSize
      };
      this.tableData = [];
      this.page.total = 0;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = DownloadDataModel.initListWithDataList(resData.rows);
      });
    },
    // 下载1
    requestExport (row) {
      if (this.tableData.length === 0) {
        return this.$Message.info('无数据可导出');
      }
      const params = {
        id: row.id
      };
      const date = (new Date()).getTime();
      const name = row.name + date + row.fileExt;
      downloadExcel('/opmp/report/download/get', params, name)
        .then(() => {
          this.$Message.success('下载成功');
        })
        .catch(e => {
          this.$Message.warning('下载失败');
        });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
