export class DownloadDataModel extends BaseModel {
  constructor () {
    super();
    // 自增主键id
    this.id = '';
    // 报表名称
    this.name = '';
    // 状态 0生成中 1已生成 2生成失败
    this.status = '';
    // 状态描述
    this.statusDesc = '';
    // 创建时间
    this.createTime = '';
    // 创建人
    this.createBy = '';
    // 生成时间
    this.generateTime = '';
    // 文件扩展名 示例: .zip或.xlsx
    this.fileExt = '';
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        id: '000101010' + i,
        tel: '13904288000',
        province: BaseModel.getFakeTitle(4) + '省',
        city: BaseModel.getFakeTitle(6) + '市',
        area: BaseModel.getFakeTitle(6) + '区',
        mainPrice: parseFloat((Math.random() * 99999).toFixed(2)),
        lightPrice: parseFloat((Math.random() * 30000).toFixed(2))
      };
      list.push(dic);
    }
    return list;
  }
}
